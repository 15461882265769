import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";    
import logo from '../images/logo.png';
import page from '../images/page.png';
import footer1 from '../images/footer1.png';
import footer2 from '../images/footer2.png';
import footer3 from '../images/footer3.png';
import footer4 from '../images/footer4.png';
import footer5 from '../images/footer5.png';
import '../wdwc.css';
import '../responsive.css';
import {Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import axios from "axios";

axios.defaults.baseURL = "https://api.wedesignwecode.com/api/"
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Cache-Control"] = "no-store";

const Home = () => {

const [posts, setPosts] = useState([]);
const cat = useLocation().search

useEffect(() => {
    const fetchData = async () => {
    try {
        const res = await axios.get(`/posts${cat}`);
        setPosts(res.data);
    } catch (err) {
        console.log(err);
    }
    };
    fetchData();
}, [cat]);

const getText = (html) =>{
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
}


const loop = 0;
const headline = posts[0];
const ID = headline?.id;
const img = headline?.img;

return (
<div className="container">
      <div className="header">
        <div className="logo">
          <a href="/"><img src={logo}  alt="Logo"/></a>
        </div>
        <div className="navbar">
          <li>Bolig</li>
          <li className="dot"><svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="2.5" cy="2.5" r="2.5" fill="black"/> </svg></li>
          <li>Sommerhus</li>
          <li className="dot"><svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="2.5" cy="2.5" r="2.5" fill="black"/> </svg></li>
          <li>Erhverv</li>
          <li className="dot"><svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="2.5" cy="2.5" r="2.5" fill="black"/> </svg></li>
          <li>Landbrug</li>
        </div>
      </div>
      <div className="hero">
      <div className="page_div">
     
      <div className="w50">
        <div className="page_area">
          <div className="page kapsar">
          <a target="_blank" href={headline?.title}>     
          {img &&        
            <img src={`https://api.wedesignwecode.com/upload/${img}`} alt="page"/>     }
          </a>
          </div>
        </div>
      </div>

      <div className="w50 ml_100">
      <div className="text_area">
        <p>Boligavisen Bornholm er Bornholms førende avis med et stort udbud af boliger til salg indenfor Privat – Sommerhus – Erhverv og landbrug. Annoncørerne arbejder professionelt og målrettet med at formidle og forene sælger med køber. Boligavisen Bornholm udkommer hver anden uge på hele Bornholm.</p>
      </div>
      <div className="iconArea"><a className="headline_link" target="_blank" href={headline?.title}>læs</a></div>

      <div className="swiper_area">
      <div className="title">
        <h2>SENESTE Aviser</h2>
        <p>Nedenfor kan du finde tidligere aviser</p>
      </div>

      <div className="images">
      <div className="sample_pagination">
        <div className="swiper_next"><svg width="22" height="37" viewBox="0 0 22 37" fill="none" xmlns="http://www.w3.org/2000/svg"> <line x1="2.06066" y1="1.93934" x2="20.0607" y2="19.9393" stroke="black" stroke-width="3"/> <line x1="1.93934" y1="35.9393" x2="19.9393" y2="17.9393" stroke="black" stroke-width="3"/> </svg></div>
        <div className="swiper_prev"><svg width="22" height="37" viewBox="0 0 22 37" fill="none" xmlns="http://www.w3.org/2000/svg"> <line x1="19.9393" y1="35.0607" x2="1.93934" y2="17.0607" stroke="black" stroke-width="3"/> <line x1="20.0607" y1="1.06066" x2="2.06066" y2="19.0607" stroke="black" stroke-width="3"/> </svg></div>
      </div>
      <Swiper
      breakpoints={{
        1000: {
          width: 1000,
          slidesPerView:4,
        },
        350: {
          width: 350,
          slidesPerView: 2,
        },
      }}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        navigation={{
          nextEl: '.swiper_next',
          prevEl: '.swiper_prev',
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
    
        {posts.map((post) => ( 
         post.id != ID ?
        <SwiperSlide>
          <div className="images_item" data-value={post.id} key={post.id}>
            <a target="_blank" href={post.title}><img src={`https://api.wedesignwecode.com/upload/${post.img}`} alt="page"/></a>
          </div>
        </SwiperSlide>:null

   
      
        ))}

       
      </Swiper>
      </div>

      </div>

      </div>

      </div>
      </div>

      <div className="footer">
        <div className="w30 footer_area">
         <span>Kontakt:</span>
         <span>Boligmarkedet A/S</span>
         <span>Mette Suhl (koordinator) </span>
         <span>Mail: mette@boligm.dk</span>
         <span>Telefon: 5140 1697</span>
        </div>




        <div className="footer_mid">
          <h2>De bornholmske ejendomsmæglere</h2>
          <div className="dobbys">

            <div className="dobby_item">
              <img className="dobby" src={footer1} alt="footer_logo"/>
            </div>

            <div className="dobby_item">
              <img className="dobby" src={footer2} alt="footer_logo"/>
            </div>

            <div className="dobby_item">
              <img className="dobby" src={footer3} alt="footer_logo"/>
            </div>

            <div className="dobby_item">
              <img className="dobby" src={footer4} alt="footer_logo"/>
            </div>

            <div className="dobby_item">
              <img className="dobby" src={footer5} alt="footer_logo"/>
            </div>

          </div>
        </div>


      </div>

      


    </div>/*container*/
    )
  
};

export default Home;